import { useState } from 'react';
import { Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

const thStyle = {
    textAlign: 'center',
    padding: '0 10px',
};

function AuftragCodesSelector({ auftragscodesData, setAuf_code }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [filters, setFilters] = useState({
        ac_code: "",
        absender: "",
        versandbanhof: "",
        versandladeplatz: "",
        empfanger: "",
        empfanger_kdnr: "",
        empfangsbahnhof: "",
        empfaengsladeplatz: "",
        nhm_nr: "",
        tarifnummer: "",
    });

    const handleClearFilter = () => setFilters({
        ac_code: "",
        absender: "",
        versandbanhof: "",
        versandladeplatz: "",
        empfanger: "",
        empfanger_kdnr: "",
        empfangsbahnhof: "",
        empfaengsladeplatz: "",
        nhm_nr: "",
        tarifnummer: "",
    })

    const filteredAuftrags = auftragscodesData.filter(entry => {
        return (
            entry.code.toLowerCase().includes(filters.ac_code.toLowerCase()) &&
            entry.absender.toLowerCase().includes(filters.absender.toLowerCase()) &&
            entry.versandbanhof.toLowerCase().includes(filters.versandbanhof.toLowerCase()) &&
            entry.vbf_nr.toLowerCase().includes(filters.versandladeplatz.toLowerCase()) &&
            entry.empfaenger.toLowerCase().includes(filters.empfanger.toLowerCase()) &&
            entry.empfaenger_kdnr.toLowerCase().includes(filters.empfanger_kdnr.toLowerCase()) &&
            entry.empfangsbahnhof.toLowerCase().includes(filters.empfangsbahnhof.toLowerCase()) &&
            entry.ebf_nr.toLowerCase().includes(filters.empfaengsladeplatz.toLowerCase()) &&
            entry.nhm_nr.toLowerCase().includes(filters.nhm_nr.toLowerCase()) &&
            entry.tarif_nr.toLowerCase().includes(filters.tarifnummer.toLowerCase())
        );
    });


    const handleFilterChange = (field, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [field]: value }));
    };

    function handleSelect(aufCode) {
        setAuf_code(aufCode)
        handleClose()
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Vordefinierte Auftragscodes
            </Button>

            <Modal show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Vordefinierte Auftragscodes:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="ac_code">
                                <Form.Label>AC Code:</Form.Label>
                                <Form.Control placeholder="" value={filters.ac_code} onChange={(e) => handleFilterChange("ac_code", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="absender">
                                <Form.Label>Absender:</Form.Label>
                                <Form.Control placeholder="" value={filters.absender} onChange={(e) => handleFilterChange("absender", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="versandbanhof">
                                <Form.Label>Versandbanhof:</Form.Label>
                                <Form.Control placeholder="" value={filters.versandbanhof} onChange={(e) => handleFilterChange("versandbanhof", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="versandladeplatz">
                                <Form.Label>Versandladeplatz:</Form.Label>
                                <Form.Control placeholder="" value={filters.versandladeplatz} onChange={(e) => handleFilterChange("versandladeplatz", e.target.value)} />
                            </Form.Group>

                        </Row>
                        <Row className="mb-3">

                            <Form.Group as={Col} controlId="empfanger">
                                <Form.Label>Empfänger:</Form.Label>
                                <Form.Control placeholder="" value={filters.empfanger} onChange={(e) => handleFilterChange("empfanger", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="empfanger_kdnr">
                                <Form.Label>Empfänger kdnr:</Form.Label>
                                <Form.Control placeholder="" value={filters.empfanger_kdnr} onChange={(e) => handleFilterChange("empfanger_kdnr", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="empfangsbahnhof">
                                <Form.Label>Empfangsbahnhof:</Form.Label>
                                <Form.Control placeholder="" value={filters.empfangsbahnhof} onChange={(e) => handleFilterChange("empfangsbahnhof", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="empfaengsladeplatz">
                                <Form.Label>Empfaengsladeplatz:</Form.Label>
                                <Form.Control placeholder="" value={filters.empfaengsladeplatz} onChange={(e) => handleFilterChange("empfaengsladeplatz", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="nhm_nr">
                                <Form.Label>NHM Nr:</Form.Label>
                                <Form.Control placeholder="" value={filters.nhm_nr} onChange={(e) => handleFilterChange("nhm_nr", e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="tarifnummer">
                                <Form.Label>Tarifnummer (Export DB Spalten AR und AS):</Form.Label>
                                <Form.Control placeholder="" value={filters.tarifnummer} onChange={(e) => handleFilterChange("tarifnummer", e.target.value)} />
                            </Form.Group>

                        </Row>
                        <Row className="mb-2">
                            <Button variant='secondary' onClick={handleClearFilter}>Clear all filters</Button>
                        </Row>

                        <Table striped bordered hover size='sm' responsive="sm">
                            <thead>
                                <tr>
                                    <th style={thStyle}><h6><b>AC Code</b></h6></th>
                                    <th style={thStyle}><h6><b>Absender</b></h6></th>
                                    <th style={thStyle}><h6><b>Versandbanhof</b></h6></th>
                                    <th style={thStyle}><h6><b>Versandladeplatz</b></h6></th>
                                    <th style={thStyle}><h6><b>Empfänger [kdnr]</b></h6></th>
                                    <th style={thStyle}><h6><b>Empfangsbahnhof</b></h6></th>
                                    <th style={thStyle}><h6><b>Empfaengsladeplatz</b></h6></th>
                                    <th style={thStyle}><h6><b>NHM Nr</b></h6></th>
                                    <th style={thStyle}><h6><b>Tarifnummer (Export DB Spalten AR und AS)</b></h6></th>

                                </tr>
                            </thead>
                            <tbody>
                                {filteredAuftrags.map((op) => {
                                    return (
                                        <tr key={op.id}>
                                            <td>{op.code}</td>
                                            <td>{op.absender}</td>
                                            <td>{op.versandbanhof}</td>
                                            <td>{op.vbf_nr}</td>
                                            <td>{op.empfaenger}   <b>[{op.empfaenger_kdnr}]</b></td>
                                            <td>{op.empfangsbahnhof}</td>
                                            <td>{op.ebf_nr}</td>
                                            <td>{op.nhm_nr}</td>
                                            <td style={thStyle}>{op.tarif_nr}</td>

                                            <td>
                                                <Button variant="primary" onClick={() => handleSelect(op)}>
                                                    Select
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </Table>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AuftragCodesSelector;